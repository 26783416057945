import React, { useState, useEffect, useMemo,useCallback } from 'react';
// import {PopUpScripter, BgScripter, AlphabetWithWordScripter} from "./Scripter";
import _ from 'lodash';
import { isVowel, handleAllExceptionLetters } from 'src/utils/pm/util';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import SoundButton from './SoundButton';
import SwitchButtonBox from './SwitchButtonBox';
import Keyboard from './Keyboard';
// import {AlphabetScripter} from "./Scripter";
import PageNum from './PageNum';
import PopupCloseButton from './PopupCloseButton';
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
import AlphabetDnD8 from './AlphabetDnD8';
import AlphabetDnD9 from './AlphabetDnD9';
import Loading from '../common/Loading';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
// import {ExpandScripter} from "./ExpandScripter";
// import {ExpandUpperScripter} from "./ExpandUpperScripter";

const correctSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/great.mp3';
const oopsSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/woops.mp3';
const wrongSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/try-again.mp3';
const MAX_WRONG_COUNT = 2;

let bookNameUnitStage = undefined;
let bookNameUnitStageAdd = undefined;

const WritePopup = ({ word, userScript, showWritePopUp, editorRef, onCorrectEnd, onClear, onClose, textInPink = '', error = false }) => {
  const [correctOrWrongSound, setCorrectOrWrongSound] = useState(undefined);
  const { level, unit, stage } = useParams();
  const [mode, setMode] = useState(false);
  const [getKey, setGetKey] = useState([]);
  const location = useLocation();
  const [wrongCount, setWrongCount] = useState(0);
  const res1 = location.pathname.split('/');

  if (level == '2a' && unit == (3 || 4) && stage == (2 || 3 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  if (level == '3a' && unit == (2 || 3 || 4 || 6 || 7 || 8 || 9) && stage == (2 || 4 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  if (level == '3b' && unit == (6 || 7 || 8) && stage == (2 || 4 || 5 || 6 || 7 || 9 || 11)) {
    bookNameUnitStage = true;
  }

  /*if(level == '4a' && unit == 5 && stage == 9){
		bookNameUnitStage = true;
		bookNameUnitStageAdd = true;

	}*/

  const handleToggle = () => {
    setMode((prevMode) => !prevMode);
  };

  useEffect(() => {
    if (error) {
      setMode(false);
    }
  }, [error]);

  const handleWriteEndKeyboard = () => {
    if (getKey.join('') === word.toLowerCase()) {
      setCorrectOrWrongSound(correctSound);
    } else {
      setCorrectOrWrongSound(wrongCount ? oopsSound : wrongSound);
    }
    setGetKey([]);
  };

  const handleWriteEnd = () => {
    if (_.isNil(word) || _.isNil(userScript)) {
      return;
    }
    if (word.toLowerCase() === userScript.join('')) {
      setCorrectOrWrongSound(correctSound);
    } else {
      setCorrectOrWrongSound(wrongCount ? oopsSound : wrongSound);
      onClear();
    }
  };
  const handleSoundEnd = () => {
    if (correctOrWrongSound === correctSound) {
      setWrongCount(0);
      onCorrectEnd();
    }

    if (correctOrWrongSound === wrongSound || correctOrWrongSound === oopsSound) {
      if (wrongCount >= MAX_WRONG_COUNT - 1) {
        setWrongCount(0);
        onCorrectEnd();
      } else {
        setWrongCount(wrongCount + 1);
      }
    }

    setCorrectOrWrongSound(undefined);
  };

  let addBookNameUnitStage = undefined;
  let letterformation = [] || undefined;
  let letterformationchecklist = [] || undefined;
  let letterformationexceptchecklist = undefined;
  let bookunitstagechecklistAddExecp = undefined;
  let exceptionWord = undefined;
  if (level == '4b' && (unit == 6 || 8 || 9 || 10) && (stage == 9 || 7)) {
    if (unit == 8) {
      letterformation = { o: true, a: true };
    }
    if (unit == 8 && stage == 9) {
      letterformation = { o: true, a: true, w: true };
      exceptionWord = 'window';
      bookunitstagechecklistAddExecp = {
        road: [{ o: true, a: true, loopkey1: 1, loopkey2: 2 }],
        coat: [{ o: true, a: true, loopkey1: 1, loopkey2: 2 }],
        blow: [{ o: true, w: true, loopkey1: 2, loopkey2: 3 }],
        toast: [{ o: true, a: true, loopkey1: 1, loopkey2: 2 }],
        window: [{ o: true, w: true, loopkey1: 4, loopkey2: 5 }],
      };
    }
    if (unit == 8 && stage == 7) {
      letterformation = { o: true, a: true, w: true };
    }
    if (unit == 10) {
      letterformation = { o: true, a: true };
    }
    if (unit == 9) {
      letterformation = { o: true, u: true, w: true };
    }
    if (unit == 6) {
      letterformation = { a: true, i: true, y: true };
    }
    addBookNameUnitStage = true;
  }

  if (level == '4a' && unit == 3 && stage == 7) {
    if (unit == 3 && stage == 7) {
      exceptionWord = 'window';
      bookunitstagechecklistAddExecp = {
        lunch: [{ loopkey1: 3, loopkey2: 4 }],
        watch: [{ loopkey1: 3, loopkey2: 4 }],
        shark: [{ loopkey1: 0, loopkey2: 1 }],
        brush: [{ loopkey1: 3, loopkey2: 4 }],
      };
    }
    addBookNameUnitStage = true;
  }

  if (level == '4a' && unit == 3 && stage == 9) {
    if (unit == 3 && stage == 9) {
      exceptionWord = 'window';
      bookunitstagechecklistAddExecp = {
        ship: [{ loopkey1: 0, loopkey2: 1 }],
        fish: [{ loopkey1: 2, loopkey2: 3 }],
        chip: [{ loopkey1: 0, loopkey2: 1 }],
        shark: [{ loopkey1: 0, loopkey2: 1 }],
        lunch: [{ loopkey1: 3, loopkey2: 4 }],
        shape: [{ loopkey1: 0, loopkey2: 1 }],
      };
    }
    addBookNameUnitStage = true;
  }

  if (level == '4a' && unit == 2 && stage == 7) {
    if (unit == 2 && stage == 7) {
      exceptionWord = 'window';
      bookunitstagechecklistAddExecp = {
        brave: [{ loopkey1: 0, loopkey2: 1 }],
        crab: [{ loopkey1: 0, loopkey2: 1 }],
        drum: [{ loopkey1: 0, loopkey2: 1 }],
        grass: [{ loopkey1: 0, loopkey2: 1 }],
      };
    }
    addBookNameUnitStage = true;
  }

  if (level == '4a' && unit == 2 && stage == 9) {
    if (unit == 2 && stage == 9) {
      exceptionWord = 'window';
      bookunitstagechecklistAddExecp = {
        dress: [{ loopkey1: 0, loopkey2: 1 }],
        grass: [{ loopkey1: 0, loopkey2: 1 }],
        crow: [{ loopkey1: 0, loopkey2: 1 }],
        crab: [{ loopkey1: 0, loopkey2: 1 }],
        dragon: [{ loopkey1: 0, loopkey2: 1 }],
      };
    }
    addBookNameUnitStage = true;
  }

  if (level == '4a' && unit == 1 && stage == 9) {
    if (unit == 1 && stage == 9) {
      exceptionWord = 'window';
      bookunitstagechecklistAddExecp = {
        sled: [{ loopkey1: 0, loopkey2: 1 }],
        slide: [{ loopkey1: 0, loopkey2: 1 }],
        blades: [{ loopkey1: 0, loopkey2: 1 }],
        slides: [{ loopkey1: 0, loopkey2: 1 }],
        flame: [{ loopkey1: 0, loopkey2: 1 }],
        flag: [{ loopkey1: 0, loopkey2: 1 }],
        slide: [{ loopkey1: 0, loopkey2: 1 }],
        flute: [{ loopkey1: 0, loopkey2: 1 }],
        blue: [{ loopkey1: 0, loopkey2: 1 }],
        black: [{ loopkey1: 0, loopkey2: 1 }],
      };
    }
    addBookNameUnitStage = true;
  }

  if (level == '4a' && unit == 1 && stage == 6) {
    if (unit == 1 && stage == 6) {
      exceptionWord = 'window';
      bookunitstagechecklistAddExecp = {
        globe: [{ loopkey1: 0, loopkey2: 1 }],
        black: [{ loopkey1: 0, loopkey2: 1 }],
        flame: [{ loopkey1: 0, loopkey2: 1 }],
        slide: [{ loopkey1: 0, loopkey2: 1 }],
        flag: [{ loopkey1: 0, loopkey2: 1 }],
        blade: [{ loopkey1: 0, loopkey2: 1 }],
      };
    }
    addBookNameUnitStage = true;
  }

  if (level == '4a' && unit == 1 && stage == 7) {
    if (unit == 1 && stage == 7) {
      exceptionWord = 'window';
      bookunitstagechecklistAddExecp = {
        globe: [{ loopkey1: 0, loopkey2: 1 }],
        black: [{ loopkey1: 0, loopkey2: 1 }],
        flame: [{ loopkey1: 0, loopkey2: 1 }],
        slide: [{ loopkey1: 0, loopkey2: 1 }],
        flag: [{ loopkey1: 0, loopkey2: 1 }],
        blade: [{ loopkey1: 0, loopkey2: 1 }],
      };
    }
    addBookNameUnitStage = true;
  }

  if (level == '4a' && unit == 5 && stage == 9) {
    if (unit == 5 && stage == 9) {
      letterformation = { c: true, g: true };
      exceptionWord = 'bicycle';
      bookunitstagechecklistAddExecp = {
        candy: [{ loopkey1: 0, loopkey2: 0 }],
        city: [{ loopkey1: 0, loopkey2: 0 }],
        bicycle: [{ loopkey1: 2, loopkey2: 2 }],
        cold: [{ loopkey1: 0, loopkey2: 0 }],
        Giraffe: [{ loopkey1: 0, loopkey2: 0 }],
      };
    }
    addBookNameUnitStage = true;
  }

  if (level == '4b' && unit == 7 && stage == 9) {
    if (unit == 7 && stage == 9) {
      letterformation = { c: true, g: true };
      exceptionWord = 'bicycle';
      bookunitstagechecklistAddExecp = {
        green: [{ loopkey1: 2, loopkey2: 3 }],
        feet: [{ loopkey1: 1, loopkey2: 2 }],
        meat: [{ loopkey1: 1, loopkey2: 2 }],
        leaf: [{ loopkey1: 1, loopkey2: 2 }],
        bee: [{ loopkey1: 1, loopkey2: 2 }],
      };
    }
    addBookNameUnitStage = true;
  }

  if (level == '3b' && unit == 9 && stage == 9) {
    if (unit == 9 && stage == 9) {
      letterformation = { e: true };
      exceptionWord = 'bicycle';
      bookunitstagechecklistAddExecp = {
        can: [{ loopkey1: -1, loopkey2: -1 }],
        cane: [{ loopkey1: 3, loopkey2: 3 }],
        tap: [{ loopkey1: -1, loopkey2: -1 }],
        tape: [{ loopkey1: 3, loopkey2: 3 }],
        kit: [{ loopkey1: -1, loopkey2: -1 }],
        kite: [{ loopkey1: 3, loopkey2: 3 }],
        pin: [{ loopkey1: -1, loopkey2: -1 }],
        pine: [{ loopkey1: 3, loopkey2: 3 }],
        hop: [{ loopkey1: -1, loopkey2: -1 }],
        hope: [{ loopkey1: 3, loopkey2: 3 }],
        pop: [{ loopkey1: -1, loopkey2: -1 }],
        pope: [{ loopkey1: 3, loopkey2: 3 }],
        tub: [{ loopkey1: -1, loopkey2: -1 }],
        tube: [{ loopkey1: 3, loopkey2: 3 }],
        cub: [{ loopkey1: -1, loopkey2: -1 }],
        cube: [{ loopkey1: 3, loopkey2: 3 }],
      };
    }
    addBookNameUnitStage = true;
  }

  if (level == '4a' && unit == 4 && (stage == 7 || 9)) {
    if (unit == 4 && stage == 7) {
      letterformation = { c: true, g: true };
      exceptionWord = 'bicycle';
      bookunitstagechecklistAddExecp = {
        teeth: [{ loopkey1: 3, loopkey2: 4 }],
        mouth: [{ loopkey1: 3, loopkey2: 4 }],
        wheel: [{ loopkey1: 0, loopkey2: 1 }],
        whip: [{ loopkey1: 0, loopkey2: 1 }],
      };
    }

    if (unit == 4 && stage == 9) {
      letterformation = { c: true, g: true };
      exceptionWord = 'bicycle';
      bookunitstagechecklistAddExecp = {
        whale: [{ loopkey1: 0, loopkey2: 1 }],
        wheel: [{ loopkey1: 0, loopkey2: 1 }],
        bath: [{ loopkey1: 2, loopkey2: 3 }],
        whisk: [{ loopkey1: 0, loopkey2: 1 }],
        thin: [{ loopkey1: 0, loopkey2: 1 }],
        thick: [{ loopkey1: 0, loopkey2: 1 }],
        white: [{ loopkey1: 0, loopkey2: 1 }],
        three: [{ loopkey1: 0, loopkey2: 1 }],
        teeth: [{ loopkey1: 3, loopkey2: 4 }],
        mouth: [{ loopkey1: 3, loopkey2: 4 }],
      };
    }

    addBookNameUnitStage = true;
  }

  if (level == '4a' && unit == 5 && stage == 7) {
    if (unit == 5) {
      letterformationexceptchecklist = {
        cut: 'c',
        face: 'c',
        gum: 'g',
        cage: 'g',
      };
    }
  }

  if (level == '2a' && unit == 1 && stage == 7) {
    if (unit == 1 && stage == 7) {
      letterformation = { c: true, g: true };
      exceptionWord = 'bicycle';
      bookunitstagechecklistAddExecp = {
        dad: [{ loopkey1: 1, loopkey2: 2 }],
        bad: [{ loopkey1: 1, loopkey2: 2 }],
        sad: [{ loopkey1: 1, loopkey2: 2 }],
        bag: [{ loopkey1: 1, loopkey2: 2 }],
        tag: [{ loopkey1: 1, loopkey2: 2 }],
        wag: [{ loopkey1: 1, loopkey2: 2 }],
        jam: [{ loopkey1: 1, loopkey2: 2 }],
        ram: [{ loopkey1: 1, loopkey2: 2 }],
        ham: [{ loopkey1: 1, loopkey2: 2 }],
      };
    }
    addBookNameUnitStage = true;
  }

  if (level == '2a' && unit == 1 && stage == 9) {
    if (unit == 1 && stage == 9) {
      letterformation = { c: true, g: true };
      exceptionWord = 'bicycle';
      bookunitstagechecklistAddExecp = {
        dad: [{ loopkey1: 1, loopkey2: 2 }],
        bad: [{ loopkey1: 1, loopkey2: 2 }],
        sad: [{ loopkey1: 1, loopkey2: 2 }],
        bag: [{ loopkey1: 1, loopkey2: 2 }],
        tag: [{ loopkey1: 1, loopkey2: 2 }],
        wag: [{ loopkey1: 1, loopkey2: 2 }],
        jam: [{ loopkey1: 1, loopkey2: 2 }],
        ram: [{ loopkey1: 1, loopkey2: 2 }],
        ham: [{ loopkey1: 1, loopkey2: 2 }],
      };
    }
    addBookNameUnitStage = true;
  }

  if (level == '2a' && unit == 2 && stage == 3) {
    if (unit == 2 && stage == 3) {
      letterformation = { c: true, g: true };
      exceptionWord = 'bicycle';
      bookunitstagechecklistAddExecp = {
        fan: [{ loopkey1: 1, loopkey2: 2 }],
        map: [{ loopkey1: 1, loopkey2: 2 }],
        cat: [{ loopkey1: 1, loopkey2: 2 }],
      };
    }
    addBookNameUnitStage = true;
  }

  if (level == '2a' && unit == 2 && stage == 7) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      man: [{ loopkey1: 1, loopkey2: 2 }],
      cat: [{ loopkey1: 1, loopkey2: 2 }],
      can: [{ loopkey1: 1, loopkey2: 2 }],
      hat: [{ loopkey1: 1, loopkey2: 2 }],
      pig: [{ loopkey1: 1, loopkey2: 2 }],
      bin: [{ loopkey1: 1, loopkey2: 2 }],
      lip: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2a' && unit == 9 && stage == 9) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      man: [{ loopkey1: 1, loopkey2: 2 }],
      cat: [{ loopkey1: 1, loopkey2: 2 }],
      can: [{ loopkey1: 1, loopkey2: 2 }],
      hat: [{ loopkey1: 1, loopkey2: 2 }],
      pig: [{ loopkey1: 1, loopkey2: 2 }],
      bin: [{ loopkey1: 1, loopkey2: 2 }],
      lip: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2a' && unit == 3 && stage == 3) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      man: [{ loopkey1: 1, loopkey2: 2 }],
      cat: [{ loopkey1: 1, loopkey2: 2 }],
      can: [{ loopkey1: 1, loopkey2: 2 }],
      hat: [{ loopkey1: 1, loopkey2: 2 }],
      pig: [{ loopkey1: 1, loopkey2: 2 }],
      bin: [{ loopkey1: 1, loopkey2: 2 }],
      lip: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2a' && unit == 3 && stage == 7) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      pig: [{ loopkey1: 1, loopkey2: 2 }],
      big: [{ loopkey1: 1, loopkey2: 2 }],
      wig: [{ loopkey1: 1, loopkey2: 2 }],
      bin: [{ loopkey1: 1, loopkey2: 2 }],
      win: [{ loopkey1: 1, loopkey2: 2 }],
      pin: [{ loopkey1: 1, loopkey2: 2 }],
      lip: [{ loopkey1: 1, loopkey2: 2 }],
      hip: [{ loopkey1: 1, loopkey2: 2 }],
      rip: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2a' && unit == 3 && stage == 9) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      wig: [{ loopkey1: 1, loopkey2: 2 }],
      pin: [{ loopkey1: 1, loopkey2: 2 }],
      rip: [{ loopkey1: 1, loopkey2: 2 }],
      win: [{ loopkey1: 1, loopkey2: 2 }],
      bin: [{ loopkey1: 1, loopkey2: 2 }],
      hip: [{ loopkey1: 1, loopkey2: 2 }],
      win: [{ loopkey1: 1, loopkey2: 2 }],
      rip: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2a' && unit == 4 && stage == 3) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      kit: [{ loopkey1: 1, loopkey2: 2 }],
      mix: [{ loopkey1: 1, loopkey2: 2 }],
      lid: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2a' && unit == 4 && stage == 7) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      kit: [{ loopkey1: 1, loopkey2: 2 }],
      sit: [{ loopkey1: 1, loopkey2: 2 }],
      hit: [{ loopkey1: 1, loopkey2: 2 }],
      mix: [{ loopkey1: 1, loopkey2: 2 }],
      fix: [{ loopkey1: 1, loopkey2: 2 }],
      six: [{ loopkey1: 1, loopkey2: 2 }],
      lid: [{ loopkey1: 1, loopkey2: 2 }],
      kid: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2a' && unit == 4 && stage == 9) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      kit: [{ loopkey1: 1, loopkey2: 2 }],
      mix: [{ loopkey1: 1, loopkey2: 2 }],
      lid: [{ loopkey1: 1, loopkey2: 2 }],
      Six: [{ loopkey1: 1, loopkey2: 2 }],
      Mix: [{ loopkey1: 1, loopkey2: 2 }],
      Hit: [{ loopkey1: 1, loopkey2: 2 }],
      hit: [{ loopkey1: 1, loopkey2: 2 }],
      fix: [{ loopkey1: 1, loopkey2: 2 }],
      kid: [{ loopkey1: 1, loopkey2: 2 }],
      lid: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2b' && unit == 6 && stage == 3) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      bug: [{ loopkey1: 1, loopkey2: 2 }],
      hug: [{ loopkey1: 1, loopkey2: 2 }],
      rug: [{ loopkey1: 1, loopkey2: 2 }],
      sun: [{ loopkey1: 1, loopkey2: 2 }],
      bun: [{ loopkey1: 1, loopkey2: 2 }],
      run: [{ loopkey1: 1, loopkey2: 2 }],
      nut: [{ loopkey1: 1, loopkey2: 2 }],
      hut: [{ loopkey1: 1, loopkey2: 2 }],
      cut: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2b' && unit == 6 && stage == 9) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      bug: [{ loopkey1: 1, loopkey2: 2 }],
      hug: [{ loopkey1: 1, loopkey2: 2 }],
      rug: [{ loopkey1: 1, loopkey2: 2 }],
      sun: [{ loopkey1: 1, loopkey2: 2 }],
      bun: [{ loopkey1: 1, loopkey2: 2 }],
      run: [{ loopkey1: 1, loopkey2: 2 }],
      nut: [{ loopkey1: 1, loopkey2: 2 }],
      hut: [{ loopkey1: 1, loopkey2: 2 }],
      cut: [{ loopkey1: 1, loopkey2: 2 }],
      Run: [{ loopkey1: 1, loopkey2: 2 }],
      Hug: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2b' && unit == 6 && stage == 7) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      bug: [{ loopkey1: 1, loopkey2: 2 }],
      hug: [{ loopkey1: 1, loopkey2: 2 }],
      rug: [{ loopkey1: 1, loopkey2: 2 }],
      sun: [{ loopkey1: 1, loopkey2: 2 }],
      bun: [{ loopkey1: 1, loopkey2: 2 }],
      run: [{ loopkey1: 1, loopkey2: 2 }],
      nut: [{ loopkey1: 1, loopkey2: 2 }],
      hut: [{ loopkey1: 1, loopkey2: 2 }],
      cut: [{ loopkey1: 1, loopkey2: 2 }],
      Run: [{ loopkey1: 1, loopkey2: 2 }],
      Hug: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2a' && unit == 2 && stage == 9) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      man: [{ loopkey1: 1, loopkey2: 2 }],
      cat: [{ loopkey1: 1, loopkey2: 2 }],
      can: [{ loopkey1: 1, loopkey2: 2 }],
      hat: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2b' && unit == 7 && stage == 3) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      tub: [{ loopkey1: 1, loopkey2: 2 }],
      mud: [{ loopkey1: 1, loopkey2: 2 }],
      gum: [{ loopkey1: 1, loopkey2: 2 }],
      cup: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2b' && unit == 7 && stage == 7) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      tub: [{ loopkey1: 1, loopkey2: 2 }],
      cub: [{ loopkey1: 1, loopkey2: 2 }],
      mud: [{ loopkey1: 1, loopkey2: 2 }],
      bud: [{ loopkey1: 1, loopkey2: 2 }],
      gum: [{ loopkey1: 1, loopkey2: 2 }],
      hum: [{ loopkey1: 1, loopkey2: 2 }],
      cup: [{ loopkey1: 1, loopkey2: 2 }],
      pup: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2b' && unit == 7 && stage == 9) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      tub: [{ loopkey1: 1, loopkey2: 2 }],
      cub: [{ loopkey1: 1, loopkey2: 2 }],
      mud: [{ loopkey1: 1, loopkey2: 2 }],
      bud: [{ loopkey1: 1, loopkey2: 2 }],
      gum: [{ loopkey1: 1, loopkey2: 2 }],
      hum: [{ loopkey1: 1, loopkey2: 2 }],
      cup: [{ loopkey1: 1, loopkey2: 2 }],
      pup: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2b' && unit == 8 && stage == 3) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      red: [{ loopkey1: 1, loopkey2: 2 }],
      hen: [{ loopkey1: 1, loopkey2: 2 }],
      jet: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2b' && unit == 8 && stage == 7) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      red: [{ loopkey1: 1, loopkey2: 2 }],
      bed: [{ loopkey1: 1, loopkey2: 2 }],
      Ted: [{ loopkey1: 1, loopkey2: 2 }],
      hen: [{ loopkey1: 1, loopkey2: 2 }],
      ten: [{ loopkey1: 1, loopkey2: 2 }],
      pen: [{ loopkey1: 1, loopkey2: 2 }],
      jet: [{ loopkey1: 1, loopkey2: 2 }],
      wet: [{ loopkey1: 1, loopkey2: 2 }],
      pet: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2b' && unit == 8 && stage == 9) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      Ten: [{ loopkey1: 1, loopkey2: 2 }],
      Ted: [{ loopkey1: 1, loopkey2: 2 }],
      bed: [{ loopkey1: 1, loopkey2: 2 }],
      jet: [{ loopkey1: 1, loopkey2: 2 }],
      Ted: [{ loopkey1: 1, loopkey2: 2 }],
      ted: [{ loopkey1: 1, loopkey2: 2 }],
      Hen: [{ loopkey1: 1, loopkey2: 2 }],
      red: [{ loopkey1: 1, loopkey2: 2 }],
      pen: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2b' && unit == 9 && stage == 3) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      dog: [{ loopkey1: 1, loopkey2: 2 }],
      pot: [{ loopkey1: 1, loopkey2: 2 }],
      fox: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2b' && unit == 9 && stage == 7) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      box: [{ loopkey1: 1, loopkey2: 2 }],
      pot: [{ loopkey1: 1, loopkey2: 2 }],
      log: [{ loopkey1: 1, loopkey2: 2 }],
      fox: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '2b' && unit == 9 && stage == 9) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      box: [{ loopkey1: 1, loopkey2: 2 }],
      pot: [{ loopkey1: 1, loopkey2: 2 }],
      log: [{ loopkey1: 1, loopkey2: 2 }],
      ox: [{ loopkey1: 0, loopkey2: 1 }],
      fox: [{ loopkey1: 1, loopkey2: 2 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '3a' && unit == 2 && stage == 9) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      same: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      game: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      cape: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      wave: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      cave: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      tape: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      cape: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '3a' && unit == 1 && stage == 9) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      date: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      cake: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      Bake: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      Lake: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      late: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      case: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      vase: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '3a' && unit == 3 && stage == 9) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      mice: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      line: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      bike: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      nine: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      rice: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      dice: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      line: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      hike: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '3a' && unit == 4 && stage == 9) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      kite: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      hide: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      bite: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      hive: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      bite: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      dive: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      hive: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      hide: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
    };
    addBookNameUnitStage = true;
  }

  if (level == '3b' && unit == 6 && stage == 9) {
    letterformation = { c: true, g: true };
    exceptionWord = 'bicycle';
    bookunitstagechecklistAddExecp = {
      dome: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      mole: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      pole: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      home: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      bone: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      cone: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      hole: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      mole: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
      hole: [{ loopkey1: 1, loopkey2: 2, loopkey3: 3 }],
    };
    addBookNameUnitStage = true;
  }
  const isPinkOrBlue = (word, index, textInPink, hex = false) => {
    const start = word.indexOf(textInPink);
    const end = start + textInPink.length - 1;
    if (index >= start && index <= end) {
      return hex ? '#f368e0' : 'pink';
    }
    return hex ? '#2e86de' : 'blue';
  };
  return (
    <>
      <S.WritePopUpWrap
        className={`popup_wrap active ${showWritePopUp ? '' : 'area_hide'} ${
          res1[2] === '2a' || (res1[2] === '2b' && res1[6] == '9') || res1[6] == '3' || res1[6] == '7' ? 'custom-popup-write' : ''
        }`}
        style={{ zIndex: '1' }}
      >
        <S.WritePopUpBG className="popup_write_wrap popup_bg">
          <S.WritePopUpBorder className="popup_border keyboard_relative">
            <S.WritePopUpTitle>Write the word.</S.WritePopUpTitle>
            <S.WritePopUpWordBox className="word_box">
              {textInPink.length > 0
                ? _.map(word, (item, idx) => (
                    <span
                      data="last***"
                      key={`pop-word-${idx}`}
                      style={{ color: isPinkOrBlue(word, idx, textInPink, true) }}
                      className={`font_${isPinkOrBlue(word, idx, textInPink, false)}`}
                    >
                      {item}
                    </span>
                  ))
                : word && bookNameUnitStage
                ? bookNameUnitStageAdd
                  ? _.map(word, (item, idx) => (
                      <span
                        data="first"
                        key={`pop-word-${idx}`}
                        style={{ color: idx == 0 ? '#f368e0' : '#2e86de' }}
                        className={`font_${idx == 0 ? 'pink' : 'blue'}`}
                      >
                        {item}
                      </span>
                    ))
                  : _.map(word, (item, idx) => (
                      <span
                        data="second"
                        key={`pop-word-${idx}`}
                        style={{ color: idx != 0 ? '#f368e0' : '#2e86de' }}
                        className={`font_${idx == 0 ? 'blue' : 'pink'}`}
                      >
                        {item}
                      </span>
                    ))
                : addBookNameUnitStage
                ? exceptionWord
                  ? _.map(word, (item, idx) => (
                      <span
                        data={`${word}`}
                        data1={`${item}`}
                        data2={`${idx}`}
                        key={`pop-word-${idx}`}
                        style={{
                          color:
                            bookunitstagechecklistAddExecp[word][0]['loopkey1'] == idx ||
                            bookunitstagechecklistAddExecp[word][0]['loopkey2'] == idx ||
                            bookunitstagechecklistAddExecp[word][0]['loopkey3'] == idx
                              ? '#f368e0'
                              : '#2e86de',
                        }}
                        className={`font_${
                          bookunitstagechecklistAddExecp[word][0]['loopkey1'] == idx ||
                          bookunitstagechecklistAddExecp[word][0]['loopkey2'] == idx ||
                          bookunitstagechecklistAddExecp[word][0]['loopkey3'] == idx
                            ? 'pink'
                            : 'blue'
                        }`}
                      >
                        {item}
                      </span>
                    ))
                  : _.map(word, (item, idx) => (
                      <span
                        data="fourth"
                        key={`pop-word-${idx}`}
                        style={{ color: letterformation[item] == true ? '#f368e0' : '#2e86de' }}
                        className={`font_${letterformation[item] == true ? 'pink' : 'blue'}`}
                      >
                        {item}
                      </span>
                    ))
                : letterformationexceptchecklist
                ? _.map(word, (item, idx) => (
                    <span
                      data="fifth"
                      key={`pop-word-${idx}`}
                      style={{ color: letterformationexceptchecklist[word] == item ? '#f368e0' : '#2e86de' }}
                      className={`font_${letterformationexceptchecklist[word] == item ? 'pink' : 'blue'}`}
                    >
                      {item}
                    </span>
                  ))
                : textInPink.length == 0
                ? _.map(word, (item, idx) => (
                    <span
                      data="last***"
                      key={`pop-word-${idx}`}
                      style={{ color: idx == 0 ? '#f368e0' : '#2e86de' }}
                      className={`font_${idx == 0 ? 'pink' : 'blue'}`}
                    >
                      {item}
                    </span>
                  ))
                : _.map(word, (item, idx) => (
                    <span
                      data="last***"
                      key={`pop-word-${idx}`}
                      className={`font_${isPinkOrBlue(word, idx, textInPink)}`}
                      style={{ color: isPinkOrBlue(word, idx, textInPink, true) }}
                    >
                      {item}
                    </span>
                  ))}
            </S.WritePopUpWordBox>
            {/* {error ? <SwitchButtonBox toggleDevice={handleToggle} device={false} /> : <SwitchButtonBox toggleDevice={handleToggle} device={true} />} */}

            {/* <PopUpScripter
							style={{height: "180px"}}
							containerStyle={{display: mode ? "flex" : "none"}}
							onWriteEnd={handleWriteEnd}
							getKey={userScript}
							editorRef={editorRef}
							onErase={onClear}
						/> */}

            <div style={{ width: '100%', display: mode ? 'none' : 'block' }}>
              <Keyboard
                imageWordWrite={true}
                disabled={!showWritePopUp || mode}
                getKey={getKey}
                setGetKey={setGetKey}
                onWriteEnd={handleWriteEndKeyboard}
              />
            </div>
          </S.WritePopUpBorder>
        </S.WritePopUpBG>
        <PopupCloseButton onClose={onClose} />
      </S.WritePopUpWrap>
      <SoundEffectPM url={correctOrWrongSound} onEnd={handleSoundEnd} />
    </>
  );
};

export default WritePopup;

export const WriteImageSoundPopup = ({
  showWritePopUp,
  image,
  word,
  num,
  total,
  userScript,
  editorRef,
  style,
  onCorrectEnd,
  onClear,
  onClose,
  isEnd = undefined,
}) => {
  const [correctOrWrongSound, setCorrectOrWrongSound] = useState('');
  const [wrongCount, setWrongCount] = useState(0);
  const [rightAnswer, setRightAnswer] = useState(undefined);
  const location = useLocation();
  const result = location.pathname.split('/');
  const handleWriteEnd = (data) => {
    if (_.isNil(word) || _.isNil(userScript)) {
      return;
    }
    const diffAlphabet = isEnd ? word.toLowerCase().slice(-1) : word.toLowerCase().slice(0, 1);
    let userInput = data.join('').toLowerCase();
    userInput = handleAllExceptionLetters(userInput, diffAlphabet);
    console.log('handleWriteEnd', diffAlphabet, userInput);

    if (diffAlphabet === userInput) {
      setCorrectOrWrongSound(correctSound);
    } else {
      setWrongCount(wrongCount + 1);
      if (wrongCount < MAX_WRONG_COUNT - 1) {
        setCorrectOrWrongSound(wrongSound);
      } else {
        setRightAnswer(diffAlphabet);
        setCorrectOrWrongSound(oopsSound);
      }
    }
  };
  const handleSoundEnd = () => {
    setCorrectOrWrongSound('');
    if (correctOrWrongSound === correctSound) {
      onCorrectEnd();
    } else if (wrongCount >= MAX_WRONG_COUNT) {
      onCorrectEnd();
    }
  };

  useEffect(() => {
    setRightAnswer(undefined);
    setWrongCount(0);
  }, [word]);
  return (
    <>
      <S.PopUpWrap className={`popup_new_wrap ${showWritePopUp ? 'active' : ''}`} style={{ zIndex: '1' }}>
        <S.PopUpRed
          className={
            isMobile
              ? (result[2] === '1a' || result[2] === '1b') && (result[6] == '5' || result[6] == '6')
                ? 'write-area-popup mobile-write-area-popup popup_red height_adjust'
                : 'popup_red height_adjust'
              : (result[2] === '1a' || result[2] === '1b') && (result[6] == '5' || result[6] == '6')
              ? 'write-area-popup popup_red height_adjust'
              : 'popup_red height_adjust'
          }
        >
          <S.PopUpHeader className="popup_header">
            <p style={{ margin: 0 }} className="title">
              Write the letter.
            </p>
          </S.PopUpHeader>
          {!_.isNil(total) && <PageNum num={num} total={total} style={{ textAlign: 'right', padding: '0px 20px 0px 20px' }} />}
          <S.PopUpContents className="popup_contents flex0" style={{ height: '50vh' }}>
            <S.PopUpInnerWrap className="inner_wrap center relative">
              <S.WordCardArea className="wordcard_area" style={{ position: 'relative' }}>
                <S.CardArea className="card_area">
                  <S.CardXSmall className="card xsmall">
                    <S.SelCard active={true} className="sel_card active">
                      {image && <img style={{ maxWidth: '70%' }} src={image} alt={word} />}
                    </S.SelCard>
                  </S.CardXSmall>
                </S.CardArea>
                <div className="cols" style={{ position: 'absolute', top: '50%', transform: 'translateY(-50%)', right: '-8rem' }}>
                  <div className="pop_btn_area">{word && <SoundButton src={`${word}.mp3`} isStartPlaying={showWritePopUp} />}</div>
                </div>
              </S.WordCardArea>
            </S.PopUpInnerWrap>
          </S.PopUpContents>
          <div className="popup_contents flex0 ">
            <dl className="prac_wrap">
              {/* <AlphabetWithWordScripter alphabet={word && word.slice(0, 1)} others={word && word.slice(1)} getKey={userScript} editorRef={editorRef} /> */}
              {/* <BgScripter
								getKey={rightAnswer || userScript}
								editorRef={editorRef}
								onErase={onClear}
								style={{
									background: `url(/images/Write_${word}${!_.isNil(isEnd) && isEnd === true ? "_" : ""}.png) center center no-repeat`,
									backgroundSize: "auto 100%",
									zIndex: "0",
								}}
								disableEnterBtn={false}
								onWriteEnd={handleWriteEnd}
							/> */}

              <AlphabetDnD9
                steps={0}
                onEnd={handleWriteEnd}
                customData={{
                  problem: [word && word.slice(0, 1)],
                  soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/',
                  title: `Write ${word.toUpperCase()}`,
                  style: {
                    background: `url(https://cdn.cloubot.com/PM/image/Write_${word}${
                      !_.isNil(isEnd) && isEnd === true ? '_' : ''
                    }.png) center center no-repeat`,
                    backgroundSize: 'auto 100%',
                    zIndex: '0',
                  },
                }}
              />
            </dl>
          </div>
          {/* <div className="popup_bottom">{!_.isNil(total) && <PageNum num={num} total={total} />}</div> */}
        </S.PopUpRed>
        <PopupCloseButton onClose={onClose} />
      </S.PopUpWrap>

      <SoundEffectPM url={correctOrWrongSound} onEnd={handleSoundEnd} />
    </>
  );
};

export const WriteImagePopup = ({
  image,
  word,
  userScript,
  showWritePopUp,
  editorRef,
  item,
  style,
  onCorrectEnd,
  onClear,
  onClose,
  isLetter = false,
}) => {
  const [correctOrWrongSound, setCorrectOrWrongSound] = useState('');
  const location = useLocation();
  const resfind = location.pathname.split('/');
  const [wrongCount, setWrongCount] = useState(0);
  const [isDropped, setIsDropped] = useState(false);

  const handleWriteEnd = () => {
    if (_.isNil(word) || _.isNil(userScript)) {
      return;
    }
    if (word.toLowerCase() === handleAllExceptionLetters(userScript.join(''), word.toLowerCase())) {
      setCorrectOrWrongSound(correctSound);
    } else {
      if (wrongCount < MAX_WRONG_COUNT - 1) {
        setCorrectOrWrongSound(wrongSound);
        setWrongCount(wrongCount + 1);
      } else {
        setCorrectOrWrongSound(oopsSound);
        setWrongCount(0);
        onCorrectEnd();
      }
      onClear();
    }
  };
  const handleSoundEnd = () => {
    if (correctOrWrongSound === correctSound) {
      onCorrectEnd();
      setWrongCount(0);
    }
    setCorrectOrWrongSound('');
  };
  const handleDragEnd = useCallback((result) => {
    const { destination } = result;
    // Extra condition to prevent auto answer
    if (_.isNil(destination) || destination.index > 0 || !destination.droppableId.startsWith('answer')) {
      setCorrectOrWrongSound(wrongSound);
      return;
    }
    setCorrectOrWrongSound(correctSound);
    setIsDropped(true);
  }, []);
  const handleDropSoundEnd = () => {
    if (correctOrWrongSound === correctSound) {
      // if (_.isNil(problem.isBackEnd)) {
      //   if (_.isNil(problem.sound)) {
      //     setSoundName(`${problem.question}_${problem.answer}_${problem.question + problem.answer}`);
      //   } else {
      //     setSoundName(problem.sound);
      //   }
      // } else {
      //   if (_.isNil(problem.sound)) {
      //     setSoundName(`${problem.answer}${problem.question}`);
      //   } else {
      //     setSoundName(problem.sound);
      //   }
      // }
    }
    setCorrectOrWrongSound(undefined);
    onCorrectEnd();
  };
  return (
    <>
      <S.WriteImagePopupWrap
        className={`popup_wrap active ${showWritePopUp ? '' : 'area_hide'} ${
          resfind[2] === '1a' || (resfind[2] === '1b' && resfind[6] == '11') ? 'cust-find-popup' : ''
        }`}
        style={{ zIndex: '1' }}
      >
        <S.PopupWriteBGWrap className="popup_write_wrap popup_bg">
          <S.PopupWriteBGBorder className="popup_border">
            <S.PopupWriteDirection>Write the {isLetter ? 'letter' : 'word'}.</S.PopupWriteDirection>
            <S.PopupWriteWordBox className="word_box" style={{ height: '20vh' }}>
              {image && <img src={image} alt={word} style={{ objectFit: 'contain', maxHeight: 300 }} />}
            </S.PopupWriteWordBox>
            <DragDropContext onDragEnd={handleDragEnd}>
              <WordDraggableBox
                words={item ? [item.letter] : undefined}
                rightAnswer={item ? item.letter : undefined}
                isDragDisabled={isDropped}
              />
              <img
                style={{ height: '2.4rem', margin: '1.1rem 0 1rem',transform:'rotate(180deg)' }}
                src="/images/pm/i_arrow_top.svg"
                alt="화살표"
                className="top_arrow"
              />
              <WordDropZone item={item} isDropped={isDropped} />
            </DragDropContext>
            {/* <BgScripter onWriteEnd={handleWriteEnd} getKey={userScript} editorRef={editorRef} style={style} onErase={onClear} /> */}
          </S.PopupWriteBGBorder>
        </S.PopupWriteBGWrap>
        <PopupCloseButton onClose={onClose} />
      </S.WriteImagePopupWrap>
      <SoundEffectPM url={correctOrWrongSound} onEnd={handleDropSoundEnd} />
    </>
  );
};

export const WriteAlphabetImageSoundPopup = ({ alphabet, image, mp3, onEnd, onClose }) => {
  const [isWriteEndLarge, setIsWriteEndLarge] = useState(false);
  const [isWriteEndSmall, setIsWriteEndSmall] = useState(false);
  const [clickedNext, setClickedNext] = useState(false);
  const [correctOrWrongSound, setCorrectOrWrongSound] = useState(undefined);
  const [wrongCount, setWrongCount] = useState(0);
  const location = useLocation();
  const res = location.pathname.split('/');

  const capitalAlphabet = useMemo(() => alphabet.toUpperCase(), [alphabet]);
  const smallAlphabet = useMemo(() => alphabet.toLowerCase(), [alphabet]);

  const handleEnd = (v) => {
    setClickedNext(true);
    setTimeout(() => {
      setClickedNext(false);
    }, 2000);
    onEnd(v);
  };

  const handleCheck = () => {
    if (isWriteEndLarge && isWriteEndSmall) {
      handleEnd(1);
    } else {
      if (wrongCount < MAX_WRONG_COUNT - 1) {
        setCorrectOrWrongSound(wrongSound);
        setWrongCount(wrongCount + 1);
      } else {
        setCorrectOrWrongSound(oopsSound);
      }
    }
  };

  const handleSoundEnd = () => {
    if (oopsSound === correctOrWrongSound) {
      setWrongCount(0);
      handleEnd(0);
    }
    setCorrectOrWrongSound(undefined);
  };

  const handleLargeEnd = (letter) => {
    if (capitalAlphabet === handleAllExceptionLetters(letter, capitalAlphabet, 'upper')) {
      setIsWriteEndLarge(true);
    }
  };

  const handleSmallEnd = (letter) => {
    if (smallAlphabet === handleAllExceptionLetters(letter, smallAlphabet, 'lower')) {
      setIsWriteEndSmall(true);
    }
  };

  return (
    <>
      <S.PopUpWrap
        className={
          (res[2] === '1a' || res[2] === '1b') && (res[6] == '2' || res[6] == '3')
            ? 'popup_new_wrap active cust-write-alphabet-popup'
            : 'popup_new_wrap active'
        }
        style={{ zIndex: '1' }}
      >
        <S.PopUpRed className="popup_red">
          <S.PopUpHeader className="popup_header">
            <p style={{ margin: 0 }} className="title">
              Write the letters.
            </p>
          </S.PopUpHeader>
          <S.PopUpContents className="popup_contents">
            <S.PopUpInnerWrap className="inner_wrap">
              <S.WordCardArea writeStage={true} className="wordcard_area col2 stage_two_write_img_wrap">
                <S.CardArea className="card_area">
                  <S.CardSmall className="card small">
                    <S.SelCard active={true} className="sel_card active">
                      <img style={{ maxWidth: '90%' }} src={image} alt="단어이미지" />
                    </S.SelCard>
                  </S.CardSmall>
                </S.CardArea>
                <S.TextArea writeStage={true} className="text_area">
                  {capitalAlphabet}
                  {smallAlphabet}
                </S.TextArea>
              </S.WordCardArea>

              <S.PopUpButtonWrap className="cols">
                <div className="pop_btn_area stage_two_write_btn">
                  <SoundButton src={mp3} isStartPlaying={true} />
                </div>
              </S.PopUpButtonWrap>
              {!clickedNext ? (
                <>
                  <S.PopUpWriteWrap className="pop_prac_area col2 stage_two_write_wrap">
                    <AlphabetDnD8
                      onEnd={handleLargeEnd}
                      customData={{
                        problem: capitalAlphabet,
                        soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/',
                        title: `Capital Letter ${capitalAlphabet}`,
                      }}
                    />
                    <hr style={{ width: '100%', backgroundColor: '#272727', height: '2px', margin: '0.5rem 0' }} />
                    <AlphabetDnD8
                      onEnd={handleSmallEnd}
                      customData={{
                        problem: smallAlphabet,
                        soundBaseUrl: 'https://cdn.cloubot.com/PM/audio/sounds/',
                        title: `Small Letter ${smallAlphabet}`,
                      }}
                    />
                  </S.PopUpWriteWrap>
                </>
              ) : (
                <S.PopUpWriteWrap className="pop_prac_area col2 stage_two_write_wrap">
                  <Loading />
                </S.PopUpWriteWrap>
              )}
            </S.PopUpInnerWrap>
          </S.PopUpContents>
          <S.PopUpBottom className="popup_bottom">
            {
              <S.PopUpBottomRight className="right">
                <S.Button2
                  className="btn_type2"
                  // href="#none"
                  onClick={(e) => {
                    // e.preventDefault();
                    handleCheck();
                  }}
                >
                  <img style={{ width: '80%' }} src="/images/pm/i_next.svg" alt="next" />
                </S.Button2>
              </S.PopUpBottomRight>
            }
          </S.PopUpBottom>
        </S.PopUpRed>
        <PopupCloseButton onClose={onClose} />
      </S.PopUpWrap>

      <SoundEffectPM url={correctOrWrongSound} onEnd={handleSoundEnd} />
      {/* </div> */}
    </>
  );
};

const WordDropZone = ({ item, isDropped }) => {
  if (!item) return false;
  return (
    <S.ImgDndAlphabetJoin className="alphabet_join" style={!_.isNil(item.isBackEnd) ? { flexDirection: 'row-reverse' } : {}}>
      <S.ImgDndAlphabetP className="font_blue">{item && item.question}</S.ImgDndAlphabetP>
      <S.AlphabetWrap style={{ flex: 'unset' }} className="alphabet_wrap">
        {item.isFrontLetter ? (
          <>
            <PhonemeDropableBox item={item} isDropped={isDropped} />
            <S.HiddenWordTxt className="text">{item.word.slice(1)}</S.HiddenWordTxt>
          </>
        ) : (
          <>
            <S.HiddenWordTxt className="text">{item.word.slice(0, -1)}</S.HiddenWordTxt>
            <PhonemeDropableBox item={item} isDropped={isDropped} />
          </>
        )}
      </S.AlphabetWrap>
      {/* {item && item.question2 && <S.ImgDndAlphabetP className="font_blue">{item.question2}</S.ImgDndAlphabetP>} */}
    </S.ImgDndAlphabetJoin>
  );
};

const WordDraggableBox = ({ words, rightAnswer, isDragDisabled }) => {
  return (
    <S.RowFlex style={{flex : '1 1',justifyContent: 'center',alignItems: 'center'}} >
      <S.AlphabetBottomWrapUl className="alphabet_wrap">
        {words &&
          words.map((item, key) => (
            <Droppable key={`outer-droppable-wrap-${item}`} droppableId={item} type={item} isDropDisabled={true}>
              {(provided) => (
                <li
                  key={`dragWords-item-${key}`}
                  ref={provided.innerRef}
                  style={isDragDisabled && rightAnswer === item ? { display: 'none' } : {}}
                >
                  <Draggable draggableId={item} index={key} isDragDisabled={isDragDisabled}>
                    {(provided, snapshot) => (
                      <S.AlphabetBox
                        row={1}
                        // row={alphabetBoxTypeChecker(item && item.indexOf('-') !== -1 ? 6 : 3)}
                        className={`alphabet_box row3 green ${item && item.indexOf('-') !== -1 ? 'long-long-width' : ''} ${
                          isDragDisabled && rightAnswer === item ? 'area_hide' : ''
                        }`}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {item && item.indexOf('-') === -1 ? (
                          item == 'ame' || 'ave' || 'ape' ? (
                            _.map(item, (each, key2) => (
                              <span
                                style={{ color: '#f368e0' }}
                                data={item}
                                key={`each-aphabet-item-${key2}`}
                                className={`font_${isVowel(each) ? 'pink' : 'pink'}`}
                              >
                                {each}
                              </span>
                            ))
                          ) : (
                            _.map(item, (each, key2) => (
                              <span
                                data={item}
                                key={`each-aphabet-item-${key2}`}
                                style={{ color: isVowel(item) ? '#f368e0' : '#2e86de' }}
                                className={`font_${isVowel(each) ? 'pink' : 'blue'}`}
                              >
                                {each}
                              </span>
                            ))
                          )
                        ) : (
                          <span>{item.replace('-', ' ')}</span>
                        )}
                      </S.AlphabetBox>
                    )}
                  </Draggable>
                  {provided.placeholder}
                </li>
              )}
            </Droppable>
          ))}
      </S.AlphabetBottomWrapUl>
    </S.RowFlex>
  );
};
const PhonemeDropableBox = ({ item, isDropped }) => {
  return (
    <li>
      <Droppable droppableId={`answer${item && item.letter}`} type={item && item.letter}>
        {(provided, snapshot) => (
          <>
            <S.AlphabetBox
              row={1}
              // row={alphabetBoxTypeChecker(3)}
              gray={!isDropped}
              className={`alphabet_box row3 ${isDropped ? 'green' : 'gray'}`}
              id={item && item.letter}
              ref={provided.innerRef}
              style={{ backgroundColor: snapshot.isDraggingOver ? '#00b3e6' : undefined }}
            >
              {isDropped &&
                _.map(item.letter, (item, key) => (
                  <span
                    data="item1"
                    key={`answer-vowel-item-${key}`}
                    style={{ color: '#f368e0' }}
                    className={`font_${isVowel(item) ? 'pink' : 'pink'}`}
                  >
                    {item}
                  </span>
                ))}
            </S.AlphabetBox>
            <div style={{ display: 'none' }}>{provided.placeholder}</div>
          </>
        )}
      </Droppable>
    </li>
  );
};
