import React, { useMemo, useState, useRef } from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { Col, Row, Typography, Button, Modal } from 'antd';
import CustomTable from 'src/components/common/CustomTable';
import BackButton from 'src/components/common/BackButton';
import { HeaderTitle, HalfWrapper } from 'src/components/common/Styles';
import { useParams, useLocation } from 'react-router-dom';
import { GET_USER_LESSONS, CLASS_LESSON_ITEM } from 'src/operations/queries/getClass';
import { BOOK_LESSON_INFO } from 'src/operations/queries/book';
import { BOOK_STAGE_LIST } from 'src/operations/queries/study';
import { ExportExcel } from 'src/utils/index';
import { DownloadOutlined, PrinterTwoTone } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { classStoreData } from 'src/operations/store';
import { getBookStageFunc, getStageColsCreo, getStageColsLucid } from 'src/utils/bookStage';
import SpeakingTestResultModal from 'src/components/Modal/SpeakingTestResultModal';
import { isAfterSchool, isPturn } from 'src/utils';
import RecordingDataModal from 'src/components/Modal/RecordingDataModal';
import { settings } from 'src/dummy/settings';
import _ from 'lodash';
const HomeWorkTable = styled(CustomTable)`
  border-top: 3px solid #a4d0e9;
  thead[class*='ant-table-thead'] th {
    border-left: 1px solid #a4d0e9;
    border-top: 1px solid #a4d0e9;
  }
  thead[class*='ant-table-thead'] th.ant-table-cell {
    text-align: center;
  }
`;

const { Title, Text } = Typography;

const DISPLAY_NAME = {
  w1: 'Intro',
  w2: 'Practice',
  w3: 'Type',
  ks1: 'Intro',
  ks2: 'dictation',
  ks3: 'Say',
  r1: 'Listening',
  r2: 'Quiz',
  s: 'Speanking Test',
  rp: 'Role play',
  pd: 'Pattern Drill',
  gr: 'Grammar Exercise',
};
const DISPLAY_NAME_ELT = {
  w1: 'Flashcard',
  w2: 'Matching',
  game: 'Words Game',
  quiz: 'Activities',
  sr: 'Fluency',
  video: 'Video',
};
const DISPLAY_NAME_PM = {
  san: 'Sound Animation',
  ls: 'Learn the Sound',
  lw: 'Learn the Words',
  ps: 'Practice the Sounds I',
  ps1: 'Practice the Sounds II',
  wam: 'Word Animation',
  pw1: 'Practice the Words I',
  pw2: 'Practice the Words II',
  s: 'Story',
  sc: 'Story Chant',
  sa: 'Story Activity',
  pa: 'Picture Activity',
  quiz: 'Quiz',
  rs1: 'Review the Sounds I',
  rs2: 'Review the Sounds II',
  rw1: 'Review the Words I',
  rw2: 'Review the Words II',
  rw3: 'Review the Words III',
  rw4: 'Review the Words IV',
  rs3: 'Review the Sounds III',
  rs4: 'Review the Sounds IV',
  cac: 'Coloring Activity',
  ws: 'Words in the Story',
  wp: 'Words Puzzle',
  ms: 'Make your Sentense',
};

export const getStageColsPM = (stageList, lessonStage) => {
  const abbrList = lessonStage.map((item) => item.name_abbr);
  return stageList.reduce((acc, cur) => {
    if (!abbrList.includes(cur.name_abbr)) {
      return [...acc];
    }
    const col = {
      key: `stage${cur.stage_no}`,
      title: DISPLAY_NAME_PM[cur.name_abbr],
      dataIndex: `stage${cur.stage_no}`,
      align: 'center',
      stage: cur.stage_no,
      book_stages: cur.book_stage,
    };
    if (['w1', 'w2', 'game'].includes(cur.name_abbr)) {
      if (acc.length === 0) {
        acc.push({ title: 'Words', children: [] });
      }
      if (acc[0]?.children) {
        acc[0].children.push(col);
      }

      return [...acc];
    }
    return [...acc, col];
  }, []);
};
export const getStageColsELT = (stageList, lessonStage) => {
  const abbrList = lessonStage.map((item) => item.name_abbr);
  return stageList.reduce((acc, cur) => {
    if (!abbrList.includes(cur.name_abbr)) {
      return [...acc];
    }
    const col = {
      key: `stage${cur.stage_no}`,
      title: DISPLAY_NAME_ELT[cur.name_abbr],
      dataIndex: `stage${cur.stage_no}`,
      align: 'center',
      stage: cur.stage_no,
      book_stages: cur.book_stage,
    };
    if (['w1', 'w2', 'game'].includes(cur.name_abbr)) {
      if (acc.length === 0) {
        acc.push({ title: 'Words', children: [] });
      }
      if (acc[0]?.children) {
        acc[0].children.push(col);
      }

      return [...acc];
    }
    return [...acc, col];
  }, []);
};

const DISPLAY_NAME_SMARTECLASS = {
  w1: 'Listen',
  w2: 'Practice',
  w3: 'Type',
  ks1: 'Unscramble',
  ks2: 'Dictation',
  game: 'Game',
  speak: 'AI Speaking',
  rq: 'Reading Quiz',
  lq: 'Listening Quiz',
  gq: 'Grammar Quiz',
  wu: 'Unscramble',
};

const sortByName = (a, b) => {
  //이름순 정렬
  if (a.name === b.name) {
    return 0;
  }
  if (a.name < b.name) {
    return -1;
  }
  return 1;
};

const columns = [
  {
    key: 'No',
    title: 'No',
    dataIndex: 'No',
    width: 50,
    align: 'center',
  },
  {
    key: 'name',
    title: 'Name',
    dataIndex: 'name',
    align: 'center',
    sorter: sortByName,
  },
];

const getStageNumber = (stages, groupCode) => {
  if (stages && stages.length > 0) {
    for (let i = 0; i < stages.length; i++) {
      if (stages[i].group_code === groupCode) {
        return parseInt(stages[i].no);
      }
    }
  }
  return -1;
};

const getRecentStudyDate = (user_study) => {
  if (!user_study || user_study.length === 0) {
    return '-';
  }

  const recentStudyDate = user_study.reduce((acc, curItem) => {
    if (!acc || moment(curItem.study_date).isAfter(acc)) {
      return curItem.study_date;
    }
    return acc;
  }, undefined);
  return moment.utc(recentStudyDate).local().format('yy-MM-DD HH:mm');
};

const getStageColsCulp = (stageList, lessonStage) => {
  return stageList.reduce((acc, cur) => {
    const col = {
      key: `stage${cur.stage_no}`,
      title: DISPLAY_NAME[cur.name_abbr],
      dataIndex: `stage${cur.stage_no}`,
      align: 'center',
      stage: cur.stage_no,
      book_stages: cur.book_stage,
    };
    if (['w1', 'w2', 'w3'].includes(cur.name_abbr)) {
      if (acc.length === 0) {
        acc.push({ title: 'Words', children: [] });
      }
      if (acc[0]?.children) {
        acc[0].children.push(col);
      }

      return [...acc];
    } else if (['ks1', 'ks2', 'ks3'].includes(cur.name_abbr)) {
      if (acc.length === 1) {
        acc.push({ title: 'Key Sentence', children: [] });
      }
      if (acc[1]?.children) {
        acc[1].children.push(col);
      }

      return [...acc];
    } else if (['r1', 'r2'].includes(cur.name_abbr)) {
      if (acc.length === 2) {
        acc.push({ title: 'Reading', children: [] });
      }
      acc[2].children.push(col);
      return [...acc];
    } else if (['pd', 'rp'].includes(cur.name_abbr)) {
      if (acc.length === 3) {
        acc.push({ title: 'Speaking Drill', children: [] });
      }
      acc[3].children.push(col);
      return [...acc];
    }
    return [...acc, col];
  }, []);
};

const getStageColsSmarteclass = (stageList, lessonStage, stageGroupfunc) => {
  const currentStages = lessonStage.map((item) => item.name_abbr);
  return stageList.reduce((acc, cur) => {
    const col = {
      key: `stage${cur.stage_no}`,
      title: DISPLAY_NAME_SMARTECLASS[cur.name_abbr],
      dataIndex: `stage${cur.stage_no}`,
      align: 'center',
      stage: cur.stage_no,
      book_stages: cur.book_stage,
    };
    return stageGroupfunc(acc, cur, col, currentStages);
  }, []);
};

const getPercentGrade = (total, score) => {
  return Math.round((100 * score) / total);
};

const LearningDetail = () => {
  const { lesson_code, class_idx } = useParams();
  const { state } = useLocation();
  const [isEssayVisible, setIsEssayVisible] = useState(false);
  const [essayTitle, setEssayTitle] = useState('');
  const [currentUserTitle, setCurrentUserTitle] = useState('');
  const [currentUserName, setCurrentUserName] = useState('');
  const [essayContent, setEssayContent] = useState('');
  const [isSpeakingVisible, setIsSpeakingVisible] = useState(false);
  const [userIdx, setUserIdx] = useState();

  const [isRecordingVisible, setIsRecordingVisible] = useState(false);
  const [recordingData, setRecordingData] = useState({});

  const companyName = useSelector(classStoreData);

  const { data: dataBookLesson } = useQuery(BOOK_LESSON_INFO, {
    fetchPolicy: 'no-cache',
    variables: { skip: !lesson_code, lessonCode: lesson_code },
  });
  const { data: dataUserLessons, loading } = useQuery(GET_USER_LESSONS, {
    fetchPolicy: 'no-cache',
    variables: { skip: !class_idx || !lesson_code, class_idx: parseInt(class_idx), lesson_code },
  });

  const { data: dataClassLesson } = useQuery(CLASS_LESSON_ITEM, {
    fetchPolicy: 'no-cache',
    variables: { skip: !class_idx || !lesson_code, class_idx: parseInt(class_idx), lesson_code },
  });

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const lessonStudyDate = useMemo(() => {
    if (state.org_date) {
      return moment(state.org_date).format('YYYY-MM-DD');
    }
    return moment(dataClassLesson?.classLessonItem?.study_date).format('YYYY-MM-DD');
  }, [dataClassLesson, state]);
  const className = useMemo(() => {
    if (dataClassLesson) {
      return dataClassLesson?.classLessonItem?.class?.name;
    }
  }, [dataClassLesson]);
  const [topCode, lessonStage, stageGroupCode] = useMemo(() => {
    return [
      dataBookLesson?.bookLessonInfo?.book?.parent_code,
      dataBookLesson?.bookLessonInfo?.stage,
      dataBookLesson?.bookLessonInfo?.stage_group_code,
    ];
  }, [dataBookLesson]);

  const { data: dataStages } = useQuery(BOOK_STAGE_LIST, {
    fetchPolicy: 'no-cache',
    skip: !topCode,
    variables: { code: topCode },
  });
  function openEssayModal(essayInfo, name, nameStr) {
    setIsEssayVisible(true);
    setEssayTitle(essayInfo?.title);
    setEssayContent(essayInfo?.content);
    setCurrentUserTitle(name);
    setCurrentUserName(nameStr);
  }
  function handleOk() {
    setIsEssayVisible(false);
  }

  const realColumn = useMemo(() => {
    let added = [];
    const etc = [
      {
        key: 'date',
        title: '완료날짜',
        dataIndex: 'date',
        align: 'center',
      },
    ];

    if (dataStages) {
      if (dataStages.bookStageList) {
        added = dataStages.bookStageList.map((item) => {
          return {
            key: `stage${item.stage_no}`,
            title: `${item.name_abbr}`,
            dataIndex: `stage${item.stage_no}`,
            align: 'center',
            stage: item.stage_no,
            book_stages: item.book_stage,
          };
        });
      }
    }

    return [...columns, ...added, ...etc];
  }, [dataStages]);

  const realColumnForTable = useMemo(() => {
    let added = [];
    const etc = [
      {
        key: 'date',
        title: '완료날짜',
        dataIndex: 'date',
        align: 'center',
      },
    ];

    if (dataStages) {
      if (dataStages.bookStageList) {
        if (isPturn(companyName)) {
          added = getStageColsSmarteclass(dataStages.bookStageList, lessonStage, getBookStageFunc(stageGroupCode));
          if (settings[companyName].isTalkingTap(companyName, topCode)) {
            console.log('talkingtap true', parseInt(lesson_code.split('L')[1][0]) <= 2);
            if (parseInt(lesson_code.split('L')[1][0]) <= 2) {
              added.push({
                key: 'stage5',
                title: 'AI Speaking',
                dataIndex: 'stage5',
                stage: 5,
                align: 'center',
                book_stages: [
                  { group_code: 'grammar', name: 'Speak', no: 5, __typename: 'BookStageOrg' },
                  { group_code: 'lc_high', name: 'Speak', no: 4, __typename: 'BookStageOrg' },
                  { group_code: 'lc_low', name: 'Speak', no: 4, __typename: 'BookStageOrg' },
                ],
              });
            } else {
              const tmp = _.cloneDeep(added[3].children);
              added[3] = tmp[0];
            }
          }
        } else if (topCode === 'PM') {
          added = getStageColsPM(dataStages.bookStageList, lessonStage);
        } else if (companyName === 'creo' || topCode === 'ESL') {
          added = getStageColsCreo(dataStages.bookStageList, lessonStage, lesson_code.toUpperCase().includes('ESL'));
        } else if (companyName === 'lucid') {
          added = getStageColsLucid(dataStages.bookStageList, lessonStage, stageGroupCode);
        } else if (topCode === 'ELT') {
          added = getStageColsELT(dataStages.bookStageList, lessonStage);
        } else {
          added = getStageColsCulp(dataStages.bookStageList, lessonStage);
        }
      }
    }
    // console.log(added, 'added');
    return [...columns, ...added, ...etc];
  }, [dataStages, lessonStage, companyName, stageGroupCode]);
  const koreaTime = (utcTime) => {
    return moment(moment.utc(utcTime).toDate()).format('MM-DD HH:mm');
  };
  const userLessons = useMemo(() => {
    if (dataUserLessons && realColumn && dataStages) {
      return dataUserLessons.getUserLesson
        .filter((e) => moment(e.study_date).format('YYYY-MM-DD') === lessonStudyDate)
        .map((item, key) => {
          const filteredUserStudy = item.user_study.filter((study) => study.user_lesson_idx);
          const obj = {
            key: `user-lesson-${key}`,
            name: `${item.user.name}(${item.user.id})`,
            nameStr: `${item.user.name}`,
            date: getRecentStudyDate(filteredUserStudy),
          };

          for (let i = 0; i < realColumn.length; i++) {
            if (realColumn[i].stage) {
              const stageNumber = getStageNumber(realColumn[i].book_stages, item.book_lesson.stage_group_code);
              obj[realColumn[i].dataIndex] = getGrade(filteredUserStudy, stageNumber, obj);
            }
          }
          return obj;
        })
        .sort(sortByName)
        .map((item, key) => ({ ...item, No: key + 1 }));
    }
    return [];
  }, [realColumn, dataUserLessons, dataStages]);
  function getGrade(user_study, stageNum, obj) {
    if (stageNum === -1) {
      return '--';
    }
    if (user_study.length === 0) {
      return <span style={{ color: '#ffa94d' }}>{settings[companyName].notPerform}</span>; //아직 안 한 거임
    }
    const findItem = user_study.find((elm) => {
      return parseInt(elm.stage_no) === parseInt(stageNum);
    });
    if (!findItem) {
      return <span style={{ color: '#ffa94d' }}>{settings[companyName].notPerform}</span>; //이것도 안 한 거임
    }
    if (findItem.exam_total) {
      //점수를 백분율로 표시..
      const findSpeaking = findItem.book_lesson.stage.find((ele) => {
        return (
          parseInt(ele.no) === parseInt(stageNum) &&
          (ele.name === 'Speaking Test' || ele.name === 'Sentence Test' || ele.name === 'Word Test')
        );
      });

      const creoSpeadReading = findItem.book_lesson.stage.find((ele) => {
        return parseInt(ele.no) === parseInt(stageNum) && (ele.name === 'Speed Reading' || ele.name === 'Fluency');
      });

      if (findSpeaking) {
        return (
          <span
            style={{ color: '#40c057', cursor: 'pointer' }}
            onClick={() => {
              setUserIdx(findItem.user_idx);
              setIsSpeakingVisible(true);
            }}
          >{`${getPercentGrade(findItem.exam_total, findItem.exam_correct)}/100`}</span>
        );
      } else if (creoSpeadReading) {
        return (
          <Button
            size="small"
            style={findItem?.recording_data ? { color: '#40c057', cursor: 'pointer' } : { color: '#40c057' }}
            onClick={() => {
              openRecordingDataModal(
                { ...findItem, accuracy: `${getPercentGrade(findItem.exam_total, findItem.exam_correct)}%` },
                obj.name,
                obj.nameStr,
              );
            }}
          >{`${getPercentGrade(findItem.exam_total, findItem.exam_correct)}${companyName === 'creo' ? '%' : ''} / ${
            findItem?.wpm || ''
          }`}</Button>
        );
      } else {
        return <span style={{ color: '#40c057' }}>{`${getPercentGrade(findItem.exam_total, findItem.exam_correct)}/100`}</span>;
      }
    }

    if (findItem.essay) {
      return (
        <Button
          onClick={() => {
            openEssayModal(findItem.essay, obj.name, obj.nameStr);
          }}
        >
          View
        </Button>
      );
    }
    return <span style={{ color: '#40c057' }}>{settings[companyName].perform}</span>; //점수가 없고 했는지만 체크하는 스테이지임
  }

  const exportToExcel = () => {
    const fileName = `${state?.className}_학습결과_${lessonStudyDate}`;
    ExportExcel(realColumnForTable, userLessons, fileName);
  };
  console.log(userLessons);
  const handleSpeakingCancel = () => {
    setIsSpeakingVisible(false);
    setUserIdx(undefined);
  };

  function openRecordingDataModal(info, name, nameStr) {
    setIsRecordingVisible(true);

    setRecordingData(info);
    setCurrentUserTitle(name);
    setCurrentUserName(nameStr);
  }

  function handleOkForRecordingData() {
    setIsRecordingVisible(false);
  }
  return (
    <>
      <div
        className="homework-result-dv"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          height: '7vh',
        }}
      >
        <HeaderTitle level={4}>Homework Result ({state?.className})</HeaderTitle>
        <div className="homework-result-bk" style={{ margin: 'auto 0' }}>
          <BackButton />
        </div>
      </div>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <HalfWrapper className="learning-halfwrapper learning-hs">
            <div
              className="learning-halfwrapper-top-header"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginBottom: 10,
                marginRight: 10,
              }}
            >
              <Title level={5}>
                {isAfterSchool(companyName) ? (
                  <>
                    {state?.lessonTitle}( <Text type="success">{lessonStudyDate}</Text> )
                  </>
                ) : (
                  <>
                    {state?.lessonTitle}( {lesson_code}, <Text type="success">{lessonStudyDate}</Text> )
                  </>
                )}
              </Title>
              {/* <Button style={{ color: '#289428' }}>+ Essay 출제</Button> */}
              <Button style={{ color: '#289428' }} icon={<DownloadOutlined />} onClick={exportToExcel}>
                Excel
              </Button>
            </div>

            <HomeWorkTable
              loading={loading}
              dataSource={userLessons}
              columns={realColumnForTable}
              pagination={false}
              size="small"
              color="#edf3fb"
              scroll={{ x: 530, y: 390 }}
            />
          </HalfWrapper>
        </Col>
      </Row>
      <Modal
        title={essayTitle}
        width="60%"
        visible={isEssayVisible}
        onOk={handleOk}
        onCancel={handleOk}
        cancelButtonProps={{ style: { display: 'none' } }}
        footer={[
          <Button onClick={handlePrint} style={{ marginRight: 10, marginLeft: 10, borderColor: '#1890ff', color: '#1890ff' }}>
            <PrinterTwoTone />
          </Button>,
          <Button type="primary" key="1" onClick={handleOk}>
            확인
          </Button>,
        ]}
      >
        <div ref={componentRef} className="show-print" style={{ padding: 20 }}>
          <div
            className="show-print-flex"
            style={{
              background: '#ebebeb',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              padding: '10px',
            }}
          >
            <img src={`/images/evine-logo.png`} style={{ width: '50px' }} alt="logo" />
            <Title level={3} style={{ padding: '0 10px' }}>
              English Vine
            </Title>
          </div>
          <div
            className="show-print-flex"
            style={{
              background: '#ebebeb',
              marginBottom: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
              padding: '10px',
            }}
          >
            <Title level={3} style={{ padding: '10px' }}>
              Student Name: {currentUserName}
            </Title>
            <Title level={3} style={{ padding: '10px', margin: 0 }}>
              Class: {className}
            </Title>
          </div>
          <Title level={4} className="show-print" style={{ padding: '0 10px', marginTop: 0 }}>
            {`Title: ${essayTitle}`}
          </Title>
          <div style={{ padding: 20, lineHeight: '60px' }} dangerouslySetInnerHTML={{ __html: essayContent }} />
        </div>
        <div class="hide-print" dangerouslySetInnerHTML={{ __html: essayContent }} />
      </Modal>
      <SpeakingTestResultModal user_idx={userIdx} lesson_code={lesson_code} visible={isSpeakingVisible} onCancel={handleSpeakingCancel} />

      <RecordingDataModal
        data={{ ...recordingData, currentUserName, className }}
        isShow={isRecordingVisible}
        onPrint={handlePrint}
        componentRef={componentRef}
        onOk={handleOkForRecordingData}
      />
    </>
  );
};

export default LearningDetail;
