import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
//import ImageMap from "image-map";
import _ from 'lodash';
import SoundEffectPM from 'src/components/pm/SoundEffectPM';
import { WriteImagePopup } from '../WritePopup';
// import {useMyScript} from "./common/MyScriptHook";
import PopUp from '../PopUp';
import { useStudyCreate } from '../../../utils/pm/api';
import * as S from 'src/pages/PhonicsMonster/style';
import styled from 'styled-components';
import { userInfoVar } from 'src/apollo/cache';
const greatSound = 'https://cdn.cloubot.com/PM/audio/sound-effect/correct.mp3';
const defaultImage = 'https://cdn.cloubot.com/PM/audio/images/level1/apple.png'; // 기본이미지를 다른 걸 쓰니까 마이스크립트가 이상하게 나옴. 원인을 모르겠음..

//숨은 그림 찾기는 step이 필요없네요.
const HiddenPictureStage = ({ unitInfo, bookInfo, stages }) => {
  const { problem: questions, bgHiddenImage1 } = stages;

  const [shows, setShows] = useState(Array(questions.length).fill(false));
  const [isCorrects, setIsCorrects] = useState(Array(questions.length).fill(false));
  const [rightSound, setRightSound] = useState('');
  const [showWritePopUp, setShowWritePopUp] = useState(false);
  const [currentRightIndex, setCurrentRightIndex] = useState(-1);
  const [remainQuesionCount, setRemainQuesionCount] = useState(questions.length);
  const [showPopUp, setShowPopUp] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  // const {myScriptEditorRef, script, onClear, error} = useMyScript();
  const history = useHistory();

  const { handleCreateStudy } = useStudyCreate({
    handleEnd: () => {
      setShowPopUp(true);
    },
  });

  const handlePopupClose = () => {
    // onClear();
    const copy = _.cloneDeep(shows);
    copy[currentRightIndex] = false;
    setShows(copy);
    setCurrentRightIndex(-1);
    setShowWritePopUp(false);
    setIsDisabled(false);
  };

  const handleClickHiddenImage = (e, idx) => {
    e.preventDefault();
    const copy = _.cloneDeep(shows);
    copy[idx] = true;
    setShows(copy);
    setRightSound(greatSound);
    setCurrentRightIndex(idx);
    setIsDisabled(true);
  };

  const handleGreatSoundEnd = () => {
    //팝업을 띄운다
    setRightSound('');
    // if (error) {
    // 	handleCorrectWriteEnd();
    // } else {
    setShowWritePopUp(true);
    // }
  };

  const handleCorrectWriteEnd = () => {
    // onClear();
    setShowWritePopUp(false);
    const copy = _.cloneDeep(shows);
    copy[currentRightIndex] = true;
    setIsCorrects(copy);

    setRemainQuesionCount(remainQuesionCount - 1);
    setIsDisabled(false);
  };

  const handleGoodJobSoundEnd = () => {
    history.replace({
      pathname: `${window.location.pathname.split('/stage')[0]}`,
      search: `${window.location.search}`,
      state: { lesson: bookInfo.stage > Object.keys(unitInfo.stage).length / 2 },
    });
  };

  useEffect(() => {
    if (showWritePopUp === false && remainQuesionCount < 1) {
      handleCreateStudy({
        lesson_code: window.sessionStorage.getItem('lesson_code'),
        stage_no: parseInt(bookInfo.stage),
        user_idx: parseInt(userInfoVar()?.idx),
        user_lesson_idx: parseInt(window.sessionStorage.getItem('user_lesson_idx')),
      });
    }
  }, [remainQuesionCount, showWritePopUp, bookInfo.bookName, bookInfo.unit, bookInfo.stage]);

  return (
    <>
      <S.MainBox style={{ justifyContent: 'space-around', position: 'relative' }} className="main_content onewrap lv1_stg11">
        <S.RowFlex style={{ alignItems: 'center', padding: '1.4rem' }} className="contents_inner row">
          <S.HiddenFindArea className="find_area">
            <S.HiddenFind className="find">
              <img style={{ maxWidth: '100%' }} src={bgHiddenImage1} alt="찾기 이미지" />
              {questions &&
                questions.map((item, key) => (
                  <HiddenImageButton
                    isDisabled={isDisabled}
                    key={`hidden-image-key${key}`}
                    isFound={shows[key]}
                    item={item}
                    idx={key}
                    onSelect={handleClickHiddenImage}
                  />
                ))}
            </S.HiddenFind>
          </S.HiddenFindArea>
          <S.HiddenWordArea className="word_area">
            <S.HiddenWordOl>
              {questions &&
                questions.map((item, key) => <ImageWithWord key={`area-key-${key}`} idx={key} item={item} isCorrect={isCorrects[key]} />)}
            </S.HiddenWordOl>
          </S.HiddenWordArea>
        </S.RowFlex>
      </S.MainBox>

      {showWritePopUp && (
        <WriteImagePopup
          item={currentRightIndex > -1 ? questions[currentRightIndex]:{}}
          image={currentRightIndex > -1 ? questions[currentRightIndex].image : defaultImage}
          word={currentRightIndex > -1 ? questions[currentRightIndex].letter : ''}
          isLetter={true}
          style={
            currentRightIndex > -1
              ? {
                  background: `url(https://cdn.cloubot.com/PM/images/Write_${questions[currentRightIndex].word}${
                    questions[currentRightIndex].isFrontLetter ? '' : '_'
                  }.png) center center no-repeat`,
                }
              : {}
          }
          userScript={{}}
          showWritePopUp={showWritePopUp}
          editorRef={{}}
          onClear={{}}
          onClose={handlePopupClose}
          onCorrectEnd={handleCorrectWriteEnd}
        />
      )}

      <SoundEffectPM url={rightSound} onEnd={handleGreatSoundEnd} />
      {showPopUp ? <PopUp popUpType="correct" setShowPopUp={setShowPopUp} onSoundEffectEnd={handleGoodJobSoundEnd} /> : undefined}
    </>
  );
};

export default HiddenPictureStage;

const ImageWithWord = ({ idx, item, isCorrect }) => {
  return (
    <S.HiddenWordLi>
      <S.HiddenWordNum className="num">
        <span>{idx + 1}</span>
      </S.HiddenWordNum>
      <S.HiddenWordImg className="img">
        <img style={{ width: '100%' }} src={item.image} alt="car" />
      </S.HiddenWordImg>
      <S.HiddenWordTxt className="text">
        <>
          {isCorrect ? (
            <>{item.word}</>
          ) : (
            <>
              {item.isFrontLetter ? (
                <>
                  <S.HiddenWordBlank className="blank"></S.HiddenWordBlank>
                  {item.word.slice(1)}
                </>
              ) : (
                <>
                  {item.word.slice(0, -1)}
                  <S.HiddenWordBlank className="blank"></S.HiddenWordBlank>
                </>
              )}
            </>
          )}
        </>
      </S.HiddenWordTxt>
    </S.HiddenWordLi>
  );
};

const HiddenImageButton = ({ idx, item, isFound, onSelect, isDisabled = false }) => {
  return (
    <S.HiddenBtn
      active={isFound}
      disabled={isDisabled}
      type="button"
      className={`ans ${isFound ? 'active' : ''}`}
      onClick={(e) => {
        onSelect(e, idx);
      }}
      style={{ top: item.coords.top, left: item.coords.left }}
    ></S.HiddenBtn>
  );
};
